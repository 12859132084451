export const links = [
    {
      id: 1,
      text: 'home',
      url: '#home',
    },
    {
      id: 2,
      text: 'Loans',
      url: '#loan',
    },
    {
      id: 3,
      text: 'about',
      url: '#about',
    },
    {
      id: 4,
      text: 'contact',
      url: '#contact',
    },
    
  ]

   export const bankLogo = [
    {id:1,img:require('../assets/img/bank/sbi.png')},
    {id:2,img:require('../assets/img/bank/bajaj.jpg')},
    {id:3,img:require('../assets/img/bank/axisBank.jpg')},
    {id:4,img:require('../assets/img/bank/capri.jpg')},
    {id:5,img:require('../assets/img/bank/icici.jpg')},
  ]