import React from "react";

function HomeBanner() {
  return (
    <>
      <section className="hero-area hero-area-lg position-relative" id="home">
        <div className="container-fluid hero-container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center text-lg-left mb-4 mb-lg-0">
              <h2 className="text-dark position-relative">
                Your <span className="B">One Stop</span> Loan
                {/* <span className="watermark">Finedict</span> */}
              </h2>
              <p className="text-dark mb-4">
                Simple & reliable finance. <br></br>
                Leave your Loan hassles to us. <br></br>
                Your journey to a wealthy Financial Life is a few steps away.
              </p>
              <ul className="list-inline">
                <li className="list-inline-item mx-1 my-2">
                  <button
                    onClick={() => {
                      var scrollDiv = document.getElementById("loan").offsetTop;
                      window.scrollTo({
                        top: scrollDiv - 50,
                        behavior: "smooth",
                      });
                    }}
                    className="btn btn-primary"
                  >
                    Apply Loan
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 banner-img">
              <img
                style={{ width: 450, height: 300 }}
                src="assets/images/banner/banner.webp"
                className="img-fluid  shadow rounded-lg"
                alt="app-interface"
                data-aos="fade-left"
                data-aos-delay="1000"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeBanner;
