import React, { useState } from "react";
import Header from "../../../layouts/Header";
import Footer from "../../../layouts/Footer";
import HomeBanner from "../HomeBanner";
import BankSlider from "../BankSlider";
import About from "../about/About";
import DownloadSection from "../DownloadSection";
import Contact from "../contact/Contact";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "react-tabs/style/react-tabs.css";
import Tabs from "../../../components/Tab";
import emailjs from 'emailjs-com';

export default function Home() {
  const [form1, setForm1] = useState({});
  const [form2, setForm2] = useState({});
  const [form3, setForm3] = useState({});
  const [form4, setForm4] = useState({});
  const [form5, setForm5] = useState({});
  const [form6, setForm6] = useState({});
  const [form7, setForm7] = useState({});

  const [feedbackValues, setfeedbackValues] = React.useState({
    f_email: "",
    f_msg: "",
  });
  React.useEffect(() => {
    AOS.init();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fvalidate = (values) => {
    const errors = {};
    if (!values.f_email) {
      errors.f_email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.f_email = "Invalid email address";
    }
    if (!values.f_msg) {
      errors.f_msg = "Message is required";
    } else {
      return true;
    }

    return errors;
  };
  const feedbackhandleSubmit = (values, setSubmitting) => {
    // createPost(values, "feedback");
    sendEmail(values)
  };

  const sendEmail = (data) => {
    const emailService = 'service_umboknq';
    const template = 'template_153bs98';
    const userID = '8eLgs08h4Iqp1bplJ';
const subject = "Feedback";
const name = data.f_email;
const message = data.f_msg
    emailjs.send(emailService, template, { name,subject, message }, userID)
      .then((response) => {
        Swal.fire({
          title: "Success",
          text: "Thank you for contacting us. we will reach out soon.",
          icon: "success",
          confirmButtonText: "ok",
        });
        setTimeout(() => window.location.reload(), 3000);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Could not process your request now. Try again later.",
          icon: "error",
          confirmButtonText: "ok",
        });
        setTimeout(() => window.location.reload(), 3000);
      });

  };

  const createPost = (values, type) => {
    if (type === "enquiry") {
      axios
        .post("https://finedict.com:3003/addEnquiry", {
          enq_email: values.email,
          enq_name: values.name,
          enq_contact_number: values.phone,
          enq_monthly_salary: values.salary,
          enq_location: values.address,
          enq_loan_amt: values.loanamt,
          created_date_time: values.created_date_time,
        })
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              title: "Success",
              text: "Thank you for contacting us. we will reach out soon.",
              icon: "success",
              confirmButtonText: "ok",
            });
            setTimeout(() => window.location.reload(), 3000);
          } else {
            Swal.fire({
              title: "Error",
              text: "Could not process your request now. Try again later.",
              icon: "error",
              confirmButtonText: "ok",
            });
            setTimeout(() => window.location.reload(), 3000);
          }
        });
    } else {
      axios
        .post("https://finedict.com:3003/addFeedback", {
          f_email: values.f_email,
          f_msg: values.f_msg,
        })
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              title: "Success",
              text: "Thank you for your valuable feedback.",
              icon: "success",
              confirmButtonText: "ok",
            });
            setTimeout(() => window.location.reload(), 3000);
          } else {
            Swal.fire({
              title: "Error",
              text: "Could not process your request now. Try again later.",
              icon: "error",
              confirmButtonText: "ok",
            });
            setTimeout(() => window.location.reload(), 3000);
          }
        });
    }
  };
  const submitDate = (type) => {
    let finalDataObj = form1;
    if (type === "2") {
      finalDataObj = form2;
    }
    if (type === "3") {
      finalDataObj = form3;
    }
    if (type === "4") {
      finalDataObj = form4;
    }
    if (type === "5") {
      finalDataObj = form5;
    }
    if (type === "6") {
      finalDataObj = form6;
    }
    if (type === "7") {
      finalDataObj = form7;
    }

    axios
      .post("https://finedict.com:3003/addEnquiry", finalDataObj)
      .then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "Success",
            text: "Thank you for contacting us. we will reach out soon.",
            icon: "success",
            confirmButtonText: "ok",
          });
          setTimeout(() => window.location.reload(), 3000);
        } else {
          Swal.fire({
            title: "Error",
            text: "Could not process your request now. Try again later.",
            icon: "error",
            confirmButtonText: "ok",
          });
          setTimeout(() => window.location.reload(), 3000);
        }
      });
  };
  return (
    <>
      <Header />
      <ToastContainer />
      <HomeBanner />
      {/* <BankSlider /> */}

      {/* Form */}
      <section style={{ paddingTop: 40 }} className="section" id="loan">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="section-title pb-2">Apply For Personal Loan</h2>
            </div>
            <Tabs submitDate={submitDate} />
          </div>
        </div>
        <img
          src="/assets/images/shape/shape-bg.png"
          className="img-fluid feature-bg-shape-2"
          alt="background-shape"
        />
      </section>

      <About />
      <DownloadSection />

      {/* Form */}
      <section
        style={{ paddingTop: 40, marginTop: 40 }}
        className="section"
        id="loan"
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2
                className="section-title pb-5"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Feedback
              </h2>
            </div>

            <div className="col-md-12 text-center">
              <Formik
                initialValues={feedbackValues}
                validate={(values) => fvalidate(values)}
                onSubmit={(values, { setSubmitting }) => {
                  feedbackhandleSubmit(values, setSubmitting);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onChange={handleChange} onSubmit={handleSubmit}>
                    <div className="col-lg-12">
                      <input
                        type="text"
                        name="f_email"
                        className="bg-light form-control mb-4"
                        placeholder="Your Email"
                      />
                    </div>
                    <label for="amount">
                      <span style={{ color: "red", marginLeft: 20 }}>
                        {errors.f_email && touched.f_email && errors.f_email}
                      </span>
                    </label>

                    <div className="col-lg-12">
                      <textarea
                        name="f_msg"
                        className="bg-light form-control mb-4"
                        placeholder="Message"
                      ></textarea>
                    </div>
                    <label for="amount">
                      <span style={{ color: "red", marginLeft: 20 }}>
                        {errors.f_msg && touched.f_msg && errors.f_msg}
                      </span>
                    </label>

                    <div className="col-12">
                      <button type="submit" className="btn btn-primary">
                        Submit Now
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>

      <Contact />
      <Footer />
    </>
  );
}
