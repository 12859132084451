import React from "react";

export default function Contact() {
  return (
    <>
      <section
        style={{ paddingTop: 0 }}
        className="position-relative section mt-5"
        id="contact"
      >
        <div className="container">
          <div className="row ">
            <div className="col-12 text-center">
              <h2
                className="section-title aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Contact Us
              </h2>
            </div>
            {/* <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0" data-aos="fade-right" >
        <div className="card border-0 shadow rounded-xs pt-5">
          <div className="card-body">
            <i className="fa fa-map-o icon-lg icon-blue icon-bg-blue icon-bg-circle mb-3"></i>
            <h4 className="mt-4 mb-3">Address</h4>
            <p>Lorem ipsum dolor sit amet consectetur elit sed do eiusmod.</p>
          </div>
        </div>
      </div> */}
            <div
              className="col-lg-6 col-sm-6 mb-4 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="card border-0 shadow rounded-xs pt-5">
                <div className="card-body">
                  <i className="fa fa-envelope-o icon-lg icon-orange icon-bg-orange icon-bg-circle mb-3"></i>
                  <h4 className="mt-4 mb-3">Email</h4>
                  <p>
                    {" "}
                    <a href="mailto:info@finedict.com">
                      info@finedict.com
                    </a>
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-sm-6 mb-4 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="card border-0 shadow rounded-xs pt-5">
                <div className="card-body">
                  <i className="fa fa-phone icon-lg icon-purple icon-bg-purple icon-bg-circle mb-3"></i>
                  <h4 className="mt-4 mb-3">Phone</h4>
                  <p>
                    <a href="tel:8000-583455">8000-583455</a>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/assets/images/dot/dot-1.png"
          className="img-fluid feature-bg-shape-1"
          alt="background-dots"
        />
      </section>
    </>
  );
}
