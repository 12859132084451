import React from "react";

export default function About() {
  return (
    <>
      <section className="section pt-0" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pl-lg-5 col-sm-12">
              <h2
                className="section-title"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                About Us
              </h2>
              <p className="mb-4" data-aos="fade-up" data-aos-delay="400">
                FinEdict financial services pvt. ltd. is on a divine journey to
                help people in a new way to reach their financial goals. We
                provide a process that is not only simple but also transparent.
              </p>
              <ul className="list-unstyled">
                <li
                  className="d-flex mb-4"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <div className="pr-3 pr-lg-5">
                    <i className="fa fa-check text-secondary bg-white shadow icon-sm"></i>
                  </div>
                  <div>
                    <h5>Process</h5>
                    <p>
                      Sit relax and enjoy a never experienced smooth process of
                      FinEdict Finance.
                    </p>
                  </div>
                </li>
                <li className="d-flex" data-aos="fade-up" data-aos-delay="550">
                  <div className="pr-3 pr-lg-5">
                    <i className="fa fa-check text-secondary bg-white shadow icon-sm"></i>
                  </div>
                  <div>
                    <h5>Security</h5>
                    <p>
                      We check your information security. Your data our
                      responsibility.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-5 col-sm-12">
              <img
                src="assets/images/about/about-final.png"
                className="img-fluid img-about"
                alt="dashboard"
                data-aos="fade-left"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
