export function formatEmail(data, type){
    if(type =="homeLoan"){
      const name=  data.home_loan_name
      const from = data.home_loan_email
       const phone= data.home_loan_phone
       const address = data.home_loan_address
       const date=  data.created_date_time
      const income=  data.home_loan_income
        const business_name=data.home_loan_business_name
       

       const message  = `<div>


<table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%;">
  <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Name</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${name}</td>
  </tr>
  <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Email</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${from}</td>
  </tr>
  <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Phone</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${phone}</td>
  </tr>
  <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Income</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${income} </td>
  </tr>
  <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Business Name</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${business_name}</td>
  </tr>
  <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Date</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${date}</td>
  </tr>
</table>
       </div>`

const subject = "Home Loan Application from " +name

console.log("home loan ", typeof message, JSON.stringify(message))
return data= {subject, name, message}
    }


    if(type =="personalLoan"){

        const name=  data.personal_loan_name
        const from = data.personal_loan_email
         const phone= data.personal_loan_phone
        const income=  data.personal_loan_income
          const department=data.personal_loan_department
          const address = data.personal_loan_address
         const date=  data.created_date_time

         const message  = `<div>


         <table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%;">
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Name</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${name}</td>
           </tr>
           <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Email</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${from}</td>
  </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Phone</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${phone}</td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Income</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${income} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Department</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${department} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Address</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${address} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Date</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${date}</td>
           </tr>
         </table>
                </div>`

const subject = "Personal Loan Application from " +name

return data= {subject, name, message}
    }
    if(type =="Professionalloan"){
        const name=  data.prof_loan_name
        const from = data.prof_loan_email
         const phone= data.prof_loan_phone
        const profession=  data.prof_loan_profession
          const address = data.prof_loan_address
         const date=  data.created_date_time

         const message  = `<div>


         <table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%;">
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Name</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${name}</td>
           </tr>
           <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Email</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${from}</td>
  </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Phone</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${phone}</td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Profession</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${profession} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Address</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${address} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Date</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${date}</td>
           </tr>
         </table>
                </div>`


const subject = "Professional Loan Application from " +name
return data= {subject, name, message}

         

    }
    if(type =="securedBusinessLoan"){
        const name=  data.sec_bus_loan_name
        const from = data.sec_bus_loan_email
         const phone= data.sec_bus_loan_phone
        const income=  data.sec_bus_loan_Income
          const address = data.sec_bus_loan_address
         const date=  data.created_date_time


         const message  = `<div>


         <table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%;">
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Name</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${name}</td>
           </tr>
           <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Email</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${from}</td>
  </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Phone</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${phone}</td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Income</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${income} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Address</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${address} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Date</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${date}</td>
           </tr>
         </table>
                </div>`

const subject = "Secure Business Loan Application from " +name
return data= {subject, name, message}

    }
    if(type =="securedOverdraftLimit"){
        const name=  data.se_draft_name
        const from = data.se_draft_email
         const phone= data.se_draft_phone

        const agriculture=  data.se_draft_agriculture
        const itr = data.se_draft_itr
          const address = data.se_draft_address
         const date=  data.created_date_time

         const message  =` <div>


         <table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%;">
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Name</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${name}</td>
           </tr>
           <tr>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Email</td>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${from}</td>
  </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Phone</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${phone}</td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Agriculture</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${agriculture} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">ITR</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${itr} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Address</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${address} </td>
           </tr>
           <tr>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Date</td>
             <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${date}</td>
           </tr>
         </table>
                </div>`


const subject = "Secured Overdraft Limit Application from " +name
return data= {subject, name, message}

    }
}
  
